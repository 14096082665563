import {
    USERDEVICE_LOADING,
    USERDEVICE_SUCCESS,
    USERDEVICE_ERROR
} from './contants'

export function loadDevices(){
    return {
        type: USERDEVICE_LOADING
    }
}

export function setDevices(devices){
    return {
        type: USERDEVICE_SUCCESS,
        devices: devices
    }
}

export function loadError(error){
    return {
        type: USERDEVICE_ERROR,
        error: error
    }
}