import {
    USERS_LOADING,
    USERS_SUCCESS,
    USERS_ERROR
} from './contants'

const INITIAL_STATE = {
    users: [],
    is_loading: true,
    message: ""
}

const UsersReducer = (state=INITIAL_STATE, action) => {
    switch(action.type){
        case USERS_LOADING:
            return state
        case USERS_SUCCESS:
            return {
                ...state,
                is_loading: false,
                users: action.users
            }
        case USERS_ERROR:
            return {
                ...state,
                users: [],
                is_loading: false,
                message: action.error
            }
        default:
            return state
    }
}

export default UsersReducer