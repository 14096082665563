import { takeEvery, call, take, fork, put, all } from "redux-saga/effects"
import { apiUrl, fetchJSON } from "../../helpers/api"
import {
    ADD_USER_LOADING
} from './contants'
import {
    adduser,
    addUserSuccess,
    setError
} from './actions'
import { getLoggedInUser } from "../../helpers/authUtils"

function* addUserAction({ name, last_name, mobile, user_id, access_key }){
    try {
        // let data = yield call(fetchJSON, 'https://outriderapi.9930i.com/users/create/local/', {
            let data = yield call(fetchJSON, `${apiUrl}/users/create/local/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "name" : name,
                "last_name" : last_name,
                "mobile" : mobile,
                "user_id" : user_id,
                "access_key" : access_key,
            })
        })
        if(data.status === 200){
            yield put(addUserSuccess(data.message))
        } else {
            yield put(setError(data.message))
        }
    } catch(error){
        yield put(setError(error))
    }
}


export function* watchLoadAddUser(){
    yield takeEvery(ADD_USER_LOADING, addUserAction)
}

export default function* addUserFlow() {
    yield all([
        fork(watchLoadAddUser)
    ])
}