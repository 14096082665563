import {
    USERS_LOADING,
    USERS_SUCCESS,
    USERS_ERROR
} from './contants'

export function loadUsers(){
    return {
        type: USERS_LOADING
    }
}

export function setUsers(users){
    return {
        type: USERS_SUCCESS,
        users: users
    }
}

export function loadError(error){
    return {
        type: USERS_ERROR,
        error: error
    }
}