import { takeEvery, call, take, fork, put, all } from "redux-saga/effects"
import { fetchJSON } from "../../helpers/api"
import { apiUrl } from "../../helpers/api"
import {
    ADD_DEVICE_LOADING
} from './contants'
import {
    addDevice,
    addDeviceSuccess,
    setError
} from './actions'
import { getLoggedInUser } from "../../helpers/authUtils"

function* addDeviceAction({hardware_id, mobile_no}){
    try {
        // let data = yield call(fetchJSON, 'https:/outriderapi.9930i.com//admin/devices/create/', {
            let data = yield call(fetchJSON, `${apiUrl}/admin/devices/create/`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${getLoggedInUser()}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "hardware_id": hardware_id,
                "mobile_no": mobile_no
            })
        })
        if(data.status == 200){
            yield put(addDeviceSuccess(data.message))
        } else {
            yield put(setError(data.message))
        }
    } catch(error){
        yield put(setError(error))
    }
}


export function* watchLoadAddDevice(){
    yield takeEvery(ADD_DEVICE_LOADING, addDeviceAction)
}

export default function* addDeviceFlow() {
    yield all([
        fork(watchLoadAddDevice)
    ])
}