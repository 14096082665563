import {
    SOCKET_CONNECTING,
    SOCKET_CONNECTED,
    SOCKET_ERROR,
    ON_SOCKET_DATA, DATA_LOADING
} from './constants';
import { eventChannel } from 'redux-saga';
import io from 'socket.io-client'
import {
    showSocketData,
    connectSocketSuccess,
    connectToSocket, 
    socketError, deviceDataSuccess
} from './actions';
import { call, fork, take, takeEvery, actionChannel, all, put } from 'redux-saga/effects';
import { apiUrl, fetchJSON } from '../../helpers/api';
import { getLoggedInUser } from '../../helpers/authUtils';
// const socket_server = "wss://outriderapi.9930i.com";
const socket_server = "wss://api.outrider.live";
let socket;

const nmea_parser = (NMEA_STRING, speed, charge, sos) => {
    function parseLatitude() {
    
      return NMEA_STRING["lat"]
      }
    
      function parseLongitude() {
        return NMEA_STRING["lon"]
      }

      function parseAltitude(){
        return NMEA_STRING["alt"]
      }

    return {
        latitude: parseLatitude(),
        longitude: parseLongitude(),
        altitude: parseAltitude(),
	sos: sos || false,
	charge: charge || 0,
	time: NMEA_STRING["time"],
	raw: NMEA_STRING["raw"],
	sraw: speed["raw"]
    }
}

const connect = (api_key, room) => {
    socket = io(socket_server, {
        transportOptions: {
            polling: {
                extraHeaders: {
                    'X-API-KEY': getLoggedInUser()
                }
            }
        },
        transports: ['polling']
    })
    return new Promise(resolve => {
        socket.on('connect', ()=>{
            socket.emit('onDeviceConnect', room)
            resolve(socket)
        })
    })
}

function* subscribe(socket){
    return new eventChannel(emit => {
        socket.on('onDeviceData', (data, speed, charge, sos, payload)=>{
            const parsed_data = nmea_parser(data, speed, charge, sos)
            emit(showSocketData(parsed_data.latitude, parsed_data.longitude, parsed_data.altitude, parsed_data.sos, parsed_data.charge, parsed_data.time, parsed_data.raw, parsed_data.sraw, payload))
        })
        return () => {}
    })
}

function* read(socket) {
    const channel = yield call(subscribe, socket)
    while(true){
        let action = yield take(channel)
        yield put(action)
    }
}

function* fetchRootDeviceData({ device: device }){
    let data = yield call(fetchJSON, `${apiUrl}/admin/devices/data/?limit=3000`, {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${getLoggedInUser()}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "device": device
        })
    })
   yield put(deviceDataSuccess(data))
}

export function* watchDeviceRecords() {
    yield takeEvery(DATA_LOADING, fetchRootDeviceData) 
}

export default function* flow(){
    yield fork(watchDeviceRecords)
    const { api_key, room } = yield take(`${SOCKET_CONNECTING}`)
    const socket = yield call(connect, api_key, room)
    yield put(connectSocketSuccess(socket))
    yield all([
        fork(read, socket)
    ])
}
