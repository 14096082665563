import React from 'react';
import Starter from '../other/Starter';
import PageTitle from '../../components/PageTitle';
import { Row, Col, Card, CardBody,Button, Input, CustomInput, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { records, defaultSorted, columns, sizePerPageRenderer } from '../tables/Advanced';
import { Link } from 'react-router-dom';
import { loadDevices } from '../../redux/userdevices/actions'
import { connect } from 'react-redux';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import axios from 'axios' 
import { getLoggedInUser } from "../../helpers/authUtils"

export const UserDevicesTable = (props) => {
    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-4">
            Showing {from} to {to} of {size} Results
        </span>
    );
    

    

    const paginationOptions = {
        paginationSize: 10,
        pageStartIndex: 1,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageRenderer: sizePerPageRenderer,
        sizePerPageList: [
            {
                text: '10',
                value: 10,
            },
            {
                text: props['records'].length,
                value: props['records'].length,
            },
        ],
    };
    

    const { SearchBar } = Search;
    const { ExportCSVButton } = CSVExport;

    return (
        <Card>
            <CardBody>
                <ToolkitProvider
                    bootstrap4
                    keyField="id"
                    data={props.records}
                    columns={props.columns}
                    search
                    exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
                    {props => (
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <SearchBar {...props.searchProps} />
                                </Col>
                            </Row>

                            <BootstrapTable
                                {...props.baseProps}
                                bordered={false}
                                defaultSorted={props.defaultSorted}
                                pagination={paginationFactory(paginationOptions)}
                                wrapperClasses="table-responsive"
                            />
                        </React.Fragment>
                    )}
                </ToolkitProvider>
            </CardBody>
        </Card>
    );
};

async function disconnectApi (data, row) {
    // console.log(data, row)
    let passData = {
        "device": row.device[0].hardware_id,
        "mobile": row.user[0].mobile
    }
    // console.log(passData)
    const respo = await axios.post('https://outriderapi.9930i.com/users/devices/deassign/',passData,{
        
        headers: {
            "Authorization": `Bearer ${getLoggedInUser()}`,
            "Content-Type": "application/json"
        }
    });
    if(respo.status === 200){
        // console.log(respo.status)
        window.location.reload();
    }
}
const disconnect = (data, row) =>{
        
        if(!data){
            return<span>
                     <Button onClick={() =>disconnectApi (data, row) } ><RiDeleteBin6Fill /></Button>
                </span>;
        }
        else{
            return<span>
            <Button disabled>Disconnected!</Button>
       </span>;
        }
}

const dataColumns = [
    {
        dataField: 'is_voice_enabled',
        text: 'User',
        sort: false,
        formatter: (cell, row) => <CustomInput readOnly disabled className="text-center" defaultChecked={cell} type="checkbox" id="exampleCustomCheckbox"/>
     },
     {
        dataField :'Image',
        text: 'Image',
        sort: false,
        formatter: (cell,row) => <img src={row.image} alt="user" class="img-fluid " width="50" height="50"></img>  
    },
    {
        dataField: 'device',
        text: 'Device ID',
        sort: true,
        formatter: (cell, row) => cell[0].hardware_id
    },
    {
        dataField: 'due_date',
        text: 'Due Date',
        sort: true,
        formatter: (cell, row) => {
            let today  = new Date()
            let dueDate = new Date(cell)
           
            if(!cell || dueDate < today  ){
                return "Expired"
            }
            else {
                let date = dueDate.toLocaleDateString();
                return date
            }
        }
    },
    {
        dataField: 'user',
        text: 'User',
        sort: true,
        formatter: (cell, row) => cell[0].first_name
    },
    {
        dataField: 'user',
        text: 'Mobile No',
        sort: true,
        formatter: (cell, row) => cell[0].mobile
    },
    {
        dataField: 'user',
        text: 'Email',
        sort: true,
        formatter: (cell, row) => {
            if(!cell[0].accounts){
                return ""
            }
            
            return cell[0].accounts.local.user_id
        }
    },
    {
        dataField: 'miscs.gender',
        text: 'Gender',
        sort: true,
    },
    {
        dataField: 'miscs.complexion',
        text: 'Complexion',
        sort: true,
    },
    {
        dataField: 'miscs.dob',
        text: 'DOB',
        sort: true,
    },
    {
        dataField: 'miscs.blood',
        text: 'Blood Group',
        sort: true,
    },
    {
        dataField: 'miscs.height',
        text: 'Height',
        sort:true,
        formatter: (cell, row) => {
            if(cell){
                return `${cell.feet}'${cell.inches}"`
            }
        }
    },
    {
        dataField: 'miscs.address',
        text: 'Address',
        sort: true,
    },
    {
        dataField: 'speed',
        text: 'Speed Limit',
        sort: true,
    },
    {
        dataField: 'refresh_rate',
        text: 'Refresh Rate',
        sort: true,
    },
    {
        dataField: 'registered_at',
        text: 'Created at',
        sort: true,
        formatter: (cell, row) => {
            let parsedDate  = new Date(cell)
            let date = parsedDate.toLocaleDateString();
            let time = parsedDate.toLocaleTimeString([],{hour: '2-digit', minute:'2-digit'})
            return `${date} ${time}`
        }
    },
    {
        dataField: 'device',
        text: 'Monitor',
        sort: false,
        formatter: (cell, row) => <Link to={`/monitor/${cell[0].hardware_id}/`}>Monitor Device</Link>
    },
    {
        dataField: 'is_deleted',
        text: 'Disconnect',
        // align: 'center',
        formatter: disconnect,
    }
]

    




class UserDevices extends React.Component {

    constructor(props){
        super(props);
    }

    

    componentDidMount(){
        this.props.loadDevices()
    }


    render(){
        return(
            <>
                <React.Fragment>
                    <Row className="page-title">
                        <Col md={2}>
                            <Link to="/analytics/users/">
                                <Button color="secondary">Users</Button>
                            </Link>
                        </Col>
                        <Col md={2}>
                            <Link to="/analytics/devices/assigned/">
                                <Button color="primary">User Devices</Button>
                            </Link>
                        </Col>
                        <Col >
                            <div class="input-group">
                            <Input placeholder="Enter the message"/>
                            <Button>Send</Button>
                            </div>
                             
                        </Col>


                    </Row>
                    <Row>
                        <Col md={12}>
                            <UserDevicesTable records={this.props.deviceState.devices} columns={dataColumns} defaultSorted={defaultSorted} />
                        </Col>
                    </Row>
                </React.Fragment>
            </>
        )
    }

}

const mapStateToProps = (state) => {
    let deviceState = state.UserDevices
    return {
        "deviceState": deviceState
    }
}

export default connect(mapStateToProps, {
    loadDevices
})(UserDevices)