import { takeEvery, call, take, fork, put, all } from "redux-saga/effects"
import { apiUrl, fetchJSON } from "../../helpers/api"
import {
    DEVICESTATUS_LOADING,
    DEVICESTATUS_SUCCESS,
    DEVICESTATUS_ERROR
} from './contants'
import {
    setDevices,
    loadError
} from './actions'
import { getLoggedInUser } from "../../helpers/authUtils"

function* fetchDevices(){
    try {
        let devices = yield call(fetchJSON, `${apiUrl}/devices/getAllDevice/`, {
        // let devices = yield call(fetchJSON, 'https://outriderapi.9930i.com/devices/getAllDevice/', {
            method: "GET",
            // headers: {
            //     "Authorization": `Bearer ${getLoggedInUser()}`,
            //     "Content-Type": "application/json"
            // }
        })
        // console.log(devices)
        yield put(setDevices(devices))
    } catch(error){
        yield put(loadError(error))
    }
}


export function* watchLoadDevices(){
    yield takeEvery(DEVICESTATUS_LOADING, fetchDevices)
}

export default function* devicesFlow() {
    yield all([
        fork(watchLoadDevices)
    ])
}