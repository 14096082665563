import React from 'react';
import Starter from '../other/Starter';
import PageTitle from '../../components/PageTitle';
import { Row, Col, Card, CardBody, Input, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { records, defaultSorted, columns, sizePerPageRenderer } from '../tables/Advanced';
import {
    loadDevices
} from '../../redux/devices/actions'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';


export const AllDevicesTable = (props) => {
    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-4">
            Showing {from} to {to} of {size} Results
        </span>
    );

    const paginationOptions = {
        paginationSize: 10,
        pageStartIndex: 1,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageRenderer: sizePerPageRenderer,
        sizePerPageList: [
            {
                text: '10',
                value: 10,
            },
            {
                text: props['records'].length,
                value: props['records'].length,
            },
        ],
    };

    const { SearchBar } = Search;
    const { ExportCSVButton } = CSVExport;

    return (
        <Card>
            <CardBody>
                <ToolkitProvider
                    bootstrap4
                    keyField="id"
                    data={props.records}
                    columns={props.columns}
                    search
                    exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
                    {props => (
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <SearchBar {...props.searchProps} />
                                </Col>
                            </Row>

                            <BootstrapTable
                                {...props.baseProps}
                                bordered={false}
                                defaultSorted={props.defaultSorted}
                                pagination={paginationFactory(paginationOptions)}
                                wrapperClasses="table-responsive"
                            />
                        </React.Fragment>
                    )}
                </ToolkitProvider>
            </CardBody>
        </Card>
    );
};


const dataColumns = [
    {
        dataField: 'hardware_id',
        text: 'Hardware ID',
        sort: false
    },
    {
        dataField: 'mobile_no',
        text: 'Mobile No',
        sort: false
    },
    {
        dataField: 'created_by',
        text: 'Created By',
        sort: false
    },
    {
        dataField: 'created_at',
        text: 'Created at',
        sort: false,
        formatter: (cell, row) => {
            let parsedDate  = new Date(cell)
            let date = parsedDate.toLocaleDateString();
            let time = parsedDate.toLocaleTimeString([],{hour: '2-digit', minute:'2-digit'})
            return `${date} ${time}`
        }
    },
    {
        dataField: 'hardware_id',
        text: 'Monitor',
        sort: false,
        formatter: (cell, row) => <Link to={`/monitor/${cell}/`}>Monitor Device</Link>
    }
]

class AllDevices extends React.Component {

    constructor(props){
        super(props);
    }

    componentDidMount(){
        this.props.loadDevices()
    }


    render(){
        return(
            <>
                <React.Fragment>
                    <Row className="page-title">
                        <Col md={12}>
                            <PageTitle
                                title={'AllDevices'}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <AllDevicesTable records={this.props.devicesData.devices} columns={dataColumns} defaultSorted={defaultSorted} />
                        </Col>
                    </Row>
                </React.Fragment>
            </>
        )
    }

}

const mapStateToProps = (state) => {
    let data = state.Devices
    return {
        devicesData: data
    }
}

export default connect(mapStateToProps, {
    loadDevices
})(AllDevices)