import {
    SOCKET_CONNECTING,
    SOCKET_CONNECTED,
    SOCKET_ERROR,
    ON_SOCKET_DATA, 
    DATA_LOADING,
    DATA_ERROR,
    DATA_SUCCESS
} from './constants';

export const connectToSocket = (api_key, room) => {
    return {
        type: SOCKET_CONNECTING,
        api_key: api_key,
        room: room
    }
}

export const connectSocketSuccess = (socket) => {
    return {
        type: SOCKET_CONNECTED,
        socket: socket
    }
}

export const socketError = (message) => {
    return {
        type: SOCKET_ERROR,
        message: message
    }
}

export const showSocketData = (latitude, longitude, altitude, sos, charge, time, raw, sraw, payload) => {
    return {
        type: ON_SOCKET_DATA,
        latitude: latitude,
        longitude: longitude,
        altitude: altitude,
	sos: sos,
	charge: charge,
	raw: raw,
	time: time,
	sraw: sraw,
    payload: payload
    }
}

export const loadDeviceData = (device) => {
    return {
        type: DATA_LOADING,
        device: device
    }
}

export const deviceDataSuccess = (data) => {
    return {
        type: DATA_SUCCESS,
        data: data
    }
}
