import {
    SOCKET_CONNECTING,
    SOCKET_CONNECTED,
    SOCKET_ERROR,
    ON_SOCKET_DATA, 
    DATA_SUCCESS,
    DATA_LOADING,
    DATA_ERROR
} from './constants';


const INITIAL_STATE = {
    latitude: 0,
    room: "",
    socket: null,
    loading: true,
    is_connecting: true,
    connected: false,
    longitude: 0,
    altitude: 0,
    data_loading: true,
    records: [],
    charge: 100,
    data_string: "",
    success_message: "",
    error_message: ""
}

const MONITOR_REDUCER = (state=INITIAL_STATE, action) => {
    switch(action.type){
        case SOCKET_CONNECTING:
            return {
                ...state,
                room: action.room,
                is_connecting: true
            }
        case SOCKET_CONNECTED:
            return {
                ...state,
                socket: action.socket,
                is_connecting: false,
                connected: true
            }
        case SOCKET_ERROR:
            return {
                ...state,
                is_connecting: false,
                error_message: action.message
            }
        case ON_SOCKET_DATA:
	console.log(action);
            return {
                ...state,
                latitude: action.latitude,
                longitude: action.longitude,
                altitude: action.altitude,
		charge: action.charge,
                data_string: action.data_string,
		records: [{ data_string: action.raw, created_at: action.time, sos: action.sos == "1" ? true : false, charge: Number.parseFloat(action.payload.c).toFixed(2), network: Number.parseFloat(action.payload.n).toFixed(2), barrier: Number.parseFloat(action.payload.nb).toFixed(2), voltage: Number.parseFloat(action.payload.bv).toFixed(2) }, ...state.records]
            }
        case DATA_LOADING:
            return {
                ...state,
                loading: true
            }
        case DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                records: action.data
            }
        default:
            return state
    }
}

export default MONITOR_REDUCER;
