// @flow

import { combineReducers } from 'redux';
import Layout from './layout/reducers';
import Auth from './auth/reducers';
import AppMenu from './appMenu/reducers';
import Monitor from './monitor/reducers';
import Users from './users/reducers';
import Summary from './dashboard/reducers';
import UserDevices from './userdevices/reducers';
import Devices from './devices/reducers';
import AddDevice from './adddevice/reducers';
import DeviceStatus from './deviceStatus/reducers';
import AddUser from './adduser/reducers'

export default combineReducers({
    Auth,
    DeviceStatus,
    AppMenu,
    Layout,
    Monitor,
    Users,
    Summary,
    UserDevices,
    Devices,
    AddDevice,
    AddUser

});
