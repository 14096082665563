import {
    ALLDEVICES_LOADING,
    ALLDEVICES_SUCCESS,
    ALLDEVICES_ERROR
} from './contants'

const INITIAL_STATE = {
    devices: [],
    is_loading: true,
    message: ""
}

const DevicesReducer = (state=INITIAL_STATE, action) => {
    switch(action.type){
        case ALLDEVICES_LOADING:
            return state
        case ALLDEVICES_SUCCESS:
            return {
                ...state,
                is_loading: false,
                devices: action.devices
            }
        case ALLDEVICES_ERROR:
            return {
                ...state,
                devices: [],
                is_loading: false,
                message: action.error
            }
        default:
            return state
    }
}

export default DevicesReducer