import React from 'react';
import { Row, Col, Card, CardBody,Alert, Button, InputGroupAddon, Label, FormGroup, CustomInput } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import PageTitle from '../../components/PageTitle';
import Loader from '../../components/Loader'
import { addDevice } from '../../redux/adddevice/actions';
import {OutTable, ExcelRenderer} from 'react-excel-renderer';

class AddDevice extends React.Component {
    constructor(props){
        super(props)
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.state = {
            hardware_id: '',
            mobile_no: '',
            rows: '',
            cols: ''
        }
    }

    handleValidSubmit = (event, values) => {
       this.props.addDevice(values.hardware_id, values.mobile_no)
    }

    fileHandler = (event) => {
        let fileObj = event.target.files[0];
    
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
          if(err){
            console.log(err);            
          }
          else{
            this.setState({
              cols: resp.cols,
              rows: resp.rows
            });
          }
        });               
    
      }

    render(){
        return(
            <React.Fragment>
            <Row className="page-title">
                <Col md={12}>
                    <PageTitle
                        title={'Form Validation'}
                    />
                </Col>
            </Row>
            <Row>
            <Col lg={12}>
            { this.props.currentState.is_loading ? <Loader /> : null }
                    <Card>
                        <CardBody>
                            <h4 className="header-title mt-0 mb-1">Add Device</h4>
                            <p className="sub-header">Kindly fill the form to add a device</p>
                            {this.props.currentState.error && <Alert color="danger" isOpen={this.props.currentState.error ? true : false}>
                                <div>{this.props.currentState.message}</div>
                            </Alert>}
                            {!this.props.currentState.error && !this.props.currentState.is_loading && <Alert color="success" isOpen={this.props.currentState.message ? true : false}>
                                <div>{this.props.currentState.message}</div>
                            </Alert>}
                            <AvForm onValidSubmit={this.handleValidSubmit} > 
                                <AvField name="hardware_id" label="Hardware ID" type="text" required />
                                <AvField name="mobile_no" label="Mobile Number" type="text" required />
                                <input type="file" onChange={this.fileHandler.bind(this)} style={{"padding":"10px"}} />
                                <div>
                                    {this.state.rows && <OutTable data={this.state.rows} columns={this.state.cols} tableClassName="ExcelTable2007" tableHeaderRowClass="heading" />}
                                </div>
                                <Button on color="primary" type="submit">
                                    Submit
                                </Button>
                            </AvForm>
                            

                        </CardBody>
                    </Card>
                </Col>
                </Row>
                </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    let currentState = state.AddDevice
    console.log(currentState)
    return {
        currentState: currentState
    }
}

export default connect(mapStateToProps, {
    addDevice
})(AddDevice)