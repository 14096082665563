import { takeEvery, call, take, fork, put, all } from "redux-saga/effects"
import { fetchJSON, apiUrl } from "../../helpers/api"
import {
    ALLDEVICES_LOADING,
    ALLDEVICES_SUCCESS,
    ALLDEVICES_ERROR
} from './contants'
import {
    setDevices,
    loadError
} from './actions'
import { getLoggedInUser } from "../../helpers/authUtils"

function* fetchDevices(){
    try {
        let devices = yield call(fetchJSON, `${apiUrl}/admin/devices/all/`, {
        // let devices = yield call(fetchJSON, 'https://outriderapi.9930i.com/admin/devices/all/', {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${getLoggedInUser()}`,
                "Content-Type": "application/json"
            }
        })
        
        yield put(setDevices(devices))
    } catch(error){
        yield put(loadError(error))
    }
}


export function* watchLoadDevices(){
    yield takeEvery(ALLDEVICES_LOADING, fetchDevices)
}

export default function* devicesFlow() {
    yield all([
        fork(watchLoadDevices)
    ])
}