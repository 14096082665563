import {
    DEVICESTATUS_LOADING,
    DEVICESTATUS_SUCCESS,
    DEVICESTATUS_ERROR
} from './contants'

export function loadDevices(){
    return {
        type: DEVICESTATUS_LOADING
    }
}

export function setDevices(devices){
    // console.log("he",devices)
    return {
        type: DEVICESTATUS_SUCCESS,
        devices: devices
    }
}

export function loadError(error){
    return {
        type: DEVICESTATUS_ERROR,
        error: error
    }
}