import {
    ADD_DEVICE_LOADING,
    ADD_DEVICE_SUCCESS,
    ADD_DEVICE_ERROR
} from './contants'

export function addDevice(hardware_id, mobile_no){
    return {
        type: ADD_DEVICE_LOADING,
        hardware_id: hardware_id,
        mobile_no: mobile_no
    }
}

export function addDeviceSuccess(message){
    return {
        type: ADD_DEVICE_SUCCESS,
        message: message
    }
}

export function setError(error){
    return {
        type: ADD_DEVICE_ERROR,
        error: error
    }
}