import React from 'react';
import Starter from '../other/Starter';
import PageTitle from '../../components/PageTitle';
import { Row, Col, Card, CardBody,Button, Input, CustomInput, Modal,ModalBody,ModalFooter,ModalHeader } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { records, defaultSorted, columns, sizePerPageRenderer } from '../tables/Advanced';
import { connect } from 'react-redux';

import {
    loadUsers
} from '../../redux/users/actions'
import { Link } from 'react-router-dom';


export const UsersTable = (props) => {
    
    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-4">
            Showing {from} to {to} of {size} Results
        </span>
    );
    

    const paginationOptions = {
        paginationSize: 10,
        pageStartIndex: 1,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageRenderer: sizePerPageRenderer,
        sizePerPageList: [
            {
                text: '10',
                value: 10,
            },
            {
                text: props['records'].length,
                value: props['records'].length,
            },
        ],
    }; 
    

    const { SearchBar } = Search;

    return (
        <>
        <Card>
            <CardBody>
                <ToolkitProvider
                    bootstrap4
                    keyField="id"
                    data={props['records']}
                    columns={props['columns']}
                    defaultSorted={props['defaultSorted']}
                    pagination={paginationFactory(paginationOptions)}
                    wrapperClasses="table-responsive"
                    search
                    >
                    {props => (
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <SearchBar {...props.searchProps} />
                                </Col>
                                
                            </Row>

                            <BootstrapTable
                                {...props.baseProps}
                                bordered={false}
                                defaultSorted={props.defaultSorted}
                                pagination={paginationFactory(paginationOptions)}
                                wrapperClasses="table-responsive"
                            />  
                            
                            
                        </React.Fragment> 
                         
                    )}
                </ToolkitProvider>
            </CardBody>
        </Card>
        </>
    );
};


class Users extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isModalOpen: false,
            dataColumns:[
                {
                    dataField :'_id',
                    text: 'Id',
                    sort: true,
                    checkboxSelection: true,
                    headerCheckboxSelection: true,
                    headerCheckboxSelectionFilteredOnly: true

                },
                {
                    dataField :'Image',
                    text: 'Image',
                    sort: false,
                    formatter: this.imageDisplay,
                },
                {
                    dataField: 'first_name',
                    text: 'User 1',
                    sort: true,
                    formatter: this.user1
                },
                {
                    dataField: 'accounts.local.user_id',
                    text: 'Email',
                    sort: true,
                    // formatter: (cell, row) => {
                    //     console.log("Ce;;;;ll", cell)
                    //     if(!cell){
                    //         return ""
                    //     }
                    //     return cell.local.user_id
                    // }
                },
                {
                    dataField: 'is_email_verified',
                    text: 'Email Verified?',
                    sort: true
                },
                {
                    dataField: 'emergency_no1',
                    text: 'Emergency No. 1',
                    sort: true,
                    formatter: this.emergencyContact1
                },
                {
                    dataField: 'emergency_no2',
                    text: 'Emergency No. 2',
                    sort: true,
                    formatter: this.emergencyContact2
                },
                // {
                //     dataField: 'sim_details',
                //     text: 'Sim Details',
                //     sort: true,
                //     formatter: this.simDetails
                // },
                {
                    dataField: 'created_at',
                    text: 'Created at',
                    sort: true,
                    formatter: (cell, row) => {
                        let parsedDate  = new Date(cell)
                        let date = parsedDate.toLocaleDateString();
                        let time = parsedDate.toLocaleTimeString([],{hour: '2-digit', minute:'2-digit'})
                        return `${date} ${time}`
                    },
                },
                {
                    dataField: 'deactivate',
                    text: 'Deactivate Account',
                    formatter: this.deactivateButton
                }
            ],
            selectedIndex: 0
        }
    }

    toggleModal = (index) => this.setState({ ...this.state, isModalOpen: !this.state.isModalOpen, selectedIndex: index});

    simDetails =(cell,row,index) => {
        return(
            <>
            <div>
                {/* <Button onClick={() => this.toggleModal(index)}>View</Button> */}
            </div>
            </>

        )
    }
    emergencyContact1 = (cell,row,index) => {
        return(
            <>
                {/*<p>{row.emergency_no1.name}</p>
                <p>{row.emergency_no1.number}</p>*/}

            </>
        )
    }
    emergencyContact2 = (cell,row,index) => {
        return(
            <>
                {/*<p>{row.emergency_no2.name}</p>
                <p>{row.emergency_no2.number}</p>*/}

            </>
        )
    }

    deactivateButton =(cell,row,index) => {
        return(
            <>
                <Button>Deactivate</Button>
            </>
        )
    }
    
    user1 = (cell,row,index) => {
        return (
            <> 
            <p>{row.first_name} {row.last_name}</p>
            <p>{row.mobile}</p>
            <p>{row.email_address}</p>        
            </>             
        );
    };
    user2 = (cell,row,index) => {
        return (
            <> 
            {/* <p>{row.first_name} {row.last_name}</p>
            <p>{row.mobile}</p>
            <p>{row.email_address}</p>         */}
            </>             
        );
    };

   
    imageDisplay = (cell,row,index) => {
        return (
            <> 
            {/* {console.log(row)} */}
                <img src={row.image} alt={row.first_name} className="img-fluid " width="50" height="50"></img>                
            </>             
        );
    };

    componentDidMount(){
        this.props.loadUsers()
    }



    render(){      
        return(
            <>
                <Modal isOpen={this.state.isModalOpen}  toggle={() => this.toggleModal(this.state.selectedIndex)}>
                    <ModalHeader >Modal title</ModalHeader>
                    <ModalBody>
                        {this.props.users.users[this.state.selectedIndex] === undefined ? "" : this.props.users.users[this.state.selectedIndex].first_name}
                    </ModalBody>
                    <ModalFooter>
                    <Button color="secondary" onClick={() => this.toggleModal(this.state.selectedIndex)}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <React.Fragment>
                    <Row className="page-title">
                    <Col md={4}>
                        <Link to="/analytics/users/">
                            <Button color="primary">Users</Button>
                        </Link>
                        </Col>
                        <Col md={4}>
                        <Link to="/analytics/devices/assigned/">
                            <Button>User Devices</Button>
                        </Link>
                        </Col>
                        <Col md={4}>
                        <Link to="/analytics/user/add">
                            <Button color="success">Add User</Button>
                        </Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <UsersTable  records={this.props.users.users} columns={this.state.dataColumns} defaultSorted={defaultSorted} />
                        </Col>                        
                    </Row>
                </React.Fragment>
            </>
        )
    }

}

const mapStateToProps = (state) => {
    let users = state.Users
    let reverseUser =  users.users.reverse()
    users["users"]=reverseUser
    return {
        "users": users
    }
}

export default connect(mapStateToProps, {
    loadUsers
})(Users)