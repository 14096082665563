import {
    ALLDEVICES_LOADING,
    ALLDEVICES_SUCCESS,
    ALLDEVICES_ERROR
} from './contants'

export function loadDevices(){
    return {
        type: ALLDEVICES_LOADING
    }
}

export function setDevices(devices){
    return {
        type: ALLDEVICES_SUCCESS,
        devices: devices
    }
}

export function loadError(error){
    return {
        type: ALLDEVICES_ERROR,
        error: error
    }
}