// @flow
import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import appMenuSaga from './appMenu/saga';
import monitorSaga from './monitor/saga';
import usersSaga from './users/saga';
import dashboardSaga from './dashboard/saga';
import userDeviceSaga from './userdevices/saga';
import devicesSaga from './devices/saga';
import addDeviceSaga from './adddevice/saga';
import addUserSaga from './adduser/saga'
import deviceStatusSaga from './deviceStatus/saga';

export default function* rootSaga(getState){
    yield all([authSaga(), deviceStatusSaga(), layoutSaga(), appMenuSaga(), monitorSaga(), usersSaga(), dashboardSaga(), userDeviceSaga(), devicesSaga(), addDeviceSaga(), addUserSaga()]);
}
