import {
    USERDEVICE_LOADING,
    USERDEVICE_SUCCESS,
    USERDEVICE_ERROR
} from './contants'

const INITIAL_STATE = {
    devices: [],
    is_loading: true,
    message: ""
}

const UsersReducer = (state=INITIAL_STATE, action) => {
    switch(action.type){
        case USERDEVICE_LOADING:
            return state
        case USERDEVICE_SUCCESS:
            return {
                ...state,
                is_loading: false,
                devices: action.devices
            }
        case USERDEVICE_ERROR:
            return {
                ...state,
                devices: [],
                is_loading: false,
                message: action.error
            }
        default:
            return state
    }
}

export default UsersReducer