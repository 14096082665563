import {
    DASHBOARD_DATA_LOADING,
    DASHBOARD_DATA_SUCCESS,
    DASHBOARD_DATA_ERROR
} from './contants'

export function loadSummary(){
    return {
        type: DASHBOARD_DATA_LOADING
    }
}

export function setSummary(payload){
    return {
        type: DASHBOARD_DATA_SUCCESS,
        payload: payload
    }
}

export function loadError(error){
    return {
        type: DASHBOARD_DATA_ERROR,
        error: error
    }
}