import {
    ADD_USER_LOADING,
    ADD_USER_SUCCESS,
    ADD_USER_ERROR
} from './contants'

const INITIAL_STATE = {
    is_loading: false,
    error: false,
    message: ""
}

const AddUserReducer = (state=INITIAL_STATE, action) => {
    switch(action.type){
        case ADD_USER_LOADING:
            return {
                ...state,
                error: false,
                message: "",
                is_loading: true
            }
        case ADD_USER_SUCCESS:
            return {
                ...state,
                is_loading: false,
                message: action.message
            }
        case ADD_USER_ERROR:
            return {
                ...state,
                users: [],
                is_loading: false,
                error: true,
                message: action.error
            }
        default:
            return state
    }
}

export default AddUserReducer