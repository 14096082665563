import { takeEvery, call, take, fork, put, all } from "redux-saga/effects"
import { fetchJSON, apiUrl } from "../../helpers/api"
import {
    USERS_LOADING,
    USERS_SUCCESS,
    USERS_ERROR
} from './contants'
import {
    setUsers,
    loadError
} from './actions'
import { getLoggedInUser } from "../../helpers/authUtils"

function* fetchUsers(){
    try {
        let users = yield call(fetchJSON, `${apiUrl}/admin/users/all/`, {
        // let users = yield call(fetchJSON, 'https://outriderapi.9930i.com/admin/users/all/', {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${getLoggedInUser()}`,
                "Content-Type": "application/json"
            }
        })
        yield put(setUsers(users["users"]))
    } catch(error){
        yield put(loadError(error))
    }
}


export function* watchLoadUsers(){
    yield takeEvery(USERS_LOADING, fetchUsers)
}

export default function* usersFlow() {
    yield all([
        fork(watchLoadUsers)
    ])
}