import React from 'react';
import Starter from '../other/Starter';
import PageTitle from '../../components/PageTitle';
import { Row, Col, Card, CardBody, Input, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { records, defaultSorted, columns, sizePerPageRenderer } from '../tables/Advanced';
import {
    loadDevices
} from '../../redux/deviceStatus/actions'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';


export const DeviceStatusTable = (props) => {
    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-4">
            Showing {from} to {to} of {size} Results
        </span>
    );

    const paginationOptions = {
        paginationSize: 10,
        pageStartIndex: 1,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageRenderer: sizePerPageRenderer,
        sizePerPageList: [
            {
                text: '10',
                value: 10,
            },
            {
                text: props['records'].length,
                value: props['records'].length,
            },
        ],
    };

    const { SearchBar } = Search;
    const { ExportCSVButton } = CSVExport;

    return (
        <Card>
            <CardBody>
                <ToolkitProvider
                    bootstrap4
                    keyField="id"
                    data={props.records}
                    columns={props.columns}
                    search
                    exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
                    {props => (
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <SearchBar {...props.searchProps} />
                                </Col>
                            </Row>

                            <BootstrapTable
                                {...props.baseProps}
                                bordered={false}
                                defaultSorted={props.defaultSorted}
                                pagination={paginationFactory(paginationOptions)}
                                wrapperClasses="table-responsive"
                            />
                        </React.Fragment>
                    )}
                </ToolkitProvider>
            </CardBody>
        </Card>
    );
};

const mailStatusFormator = (data, row) =>{
    if(data){
        return<span style={{height: "14px",
            width: "14px",
            backgroundColor: "green",
            borderRadius: "50%",
            display: "inline-block"}}></span>;
    }
    else{
        return<span style={{height: "14px",
        width: "14px",
        backgroundColor: "red",
        borderRadius: "50%",
        display: "inline-block"}}></span>;
    }
}
const dataColumns = [
    {
        dataField: 'sos_email_status',
        text: 'Status',
        sort: true,
        align: 'center',
        formatter: mailStatusFormator,
    },
    {
        dataField: 'hardware_id',
        text: 'Hardware ID',
        sort: false
    },
    // {
    //     dataField: 'mobile_no',
    //     text: 'Mobile No',
    //     sort: false
    // },
    {
        dataField: 'location',
        text: 'Last Location',
        sort: false
    },
    {
        dataField: 'charge',
        text: 'Battery Status',
        sort: true
    },
    {
        dataField: 'doc',
        text: 'Last Communicated',
        sort: true,
        formatter: (cell, row) => {
            let parsedDate  = new Date(cell)
            let date = parsedDate.toLocaleDateString();
            let time = parsedDate.toLocaleTimeString([],{hour: '2-digit', minute:'2-digit'})
            return `${date} ${time}`
        }
    }
]

class DeviceStatus extends React.Component {

    constructor(props){
        super(props);
    }

    componentDidMount(){
        this.props.loadDevices()
    }


    render(){
        return(
            <>
                <React.Fragment>
                    <Row className="page-title">
                        <Col md={12}>
                            <PageTitle
                                title={'DeviceStatus'}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <DeviceStatusTable records={this.props.devicesData.devices} columns={dataColumns} defaultSorted={defaultSorted} />
                        </Col>
                    </Row>
                </React.Fragment>
            </>
        )
    }

}

const mapStateToProps = (state) => {
    let data = state.DeviceStatus
    return {
        devicesData: data
    }
}

export default connect(mapStateToProps, {
    loadDevices
})(DeviceStatus)