import React from 'react';
import { GoogleApiWrapper, Map } from 'google-maps-react';
import { Row, Col, CardBody, Card } from 'reactstrap';
import PageTitle from '../../components/PageTitle';
import { sizePerPageRenderer } from '../tables/Advanced';
import MapView from './MapView';
import { connect } from 'react-redux';
import { connectToSocket, loadDeviceData } from '../../redux/monitor/actions'
import StatisticsWidget from '../../components/StatisticsWidget';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const rowEvents = {
    onClick: (e, row, rowIndex) => {
      
    }
  }
  
const columns = [
    {
        dataField: 'data_string',
        text: 'Data',
        sort: false,
    },
    {
        dataField: 'created_at',
        text: 'Time Stamp',
        sort: true,
	formatter: (cell, row) => {
         const d = new Date(cell)
         return `${d.getDate()}-${d.getMonth()}-${d.getFullYear()} ${d.getHours() > 12 ? d.getHours() - 12 : d.getHours()}:${d.getMinutes()}:${d.getSeconds()}${d.getHours() > 12 ? " PM" : " AM" }`
        }
    }, 
    {
        dataField: 'sos',
        text: 'SOS',
        sort: false
    }, 
    {
        dataField: 'charge',
        text: 'Charge',
        sort: false
    },
    {
        dataField: 'voltage',
        text: 'Voltage',
        sort: false
    },
    {
        dataField: 'network',
        text: 'Network',
        sort: false
    }, 
    {
        dataField: 'barrier',
        text: 'Network Barrier',
        sort: false
    }
];

export const defaultSorted = [
    {
        dataField: 'created_at',
        order: 'desc',
    },
];

export const TableWithPaginationAndSort = ({records}) => {
    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-4">
            Showing {from} to {to} of {size} Results
        </span>
    );

    const paginationOptions = {
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageRenderer: sizePerPageRenderer,
        sizePerPageList: [
            {
                text: '5',
                value: 5,
            },
            {
                text: '10',
                value: 10,
            },
            {
                text: '25',
                value: 25,
            },
            {
                text: `${records.length}`,
                value: records.length,
            },
        ], // A numeric array is also available. the purpose of above example is custom the text
    };

    return (
        <Card>
            <CardBody>
                <h4 className="header-title mt-0 mb-1">Data Records</h4>
                <p className="sub-header">Last 200 records</p>

                <BootstrapTable
                    bootstrap4
                    keyField="created_at"
                    data={records}
                    columns={columns}
                    defaultSorted={defaultSorted}
                    pagination={paginationFactory(paginationOptions)}
                    wrapperClasses="table-responsive"
                />
            </CardBody>
        </Card>
    );
};

class Monitor extends React.Component {

    constructor(props){
        super(props);
    }

    componentDidMount(){
        this.props.connectToSocket("23", this.props.match.params["deviceID"])
        this.props.loadDeviceData(this.props.match.params["deviceID"])
    }

    render(){
        return (
            <React.Fragment>
                <Row className="page-title">
                    <Col md={12}>
                        <PageTitle
                            breadCrumbItems={[
                                { label: 'Pages', path: '/pages/starter' },
                                { label: 'Starter', path: '/pages/starter', active: true },
                            ]}
                            
                            title={`Device Status: ${this.props.deviceData.is_connecting ? 'Loading' : this.props.deviceData.connected ? 'Online' : !this.props.deviceData.connected && !this.props.deviceData.is_connecting ? 'Error': 'Offline'}`}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6} xl={3}>
                        <StatisticsWidget description="Current Latitude" title={`${this.props.deviceData["latitude"].toFixed(8)}`}></StatisticsWidget>
                    </Col>
                    <Col md={6} xl={3}>
                        <StatisticsWidget description="Current Longitude" title={`${this.props.deviceData["longitude"].toFixed(8)}`} ></StatisticsWidget>
                    </Col>
                    <Col md={6} xl={3}>
                        <StatisticsWidget description="Current Altitude" title={`${this.props.deviceData["altitude"].toFixed(3)} M`}></StatisticsWidget>
                    </Col>
                    <Col md={6} xl={3}>
                        <StatisticsWidget description="Charge Left" title={`${this.props.deviceData["charge"]}%`}></StatisticsWidget>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12} >
                        <Card style={{
                            height: 400
                        }} >
                            <CardBody>
                                <MapView latitude={`${this.props.deviceData["latitude"]}`} longitude={`${this.props.deviceData["longitude"]}`} />
                            </CardBody>
                        </Card> 
                    </Col>    
                </Row>
                <Row>
                    <Col md={3} xl={12}>
                        <TableWithPaginationAndSort records={this.props.deviceData["records"]} />
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const data = state.Monitor
    return {
        deviceData: data
    }
}

export default connect(mapStateToProps, {
    connectToSocket, loadDeviceData
})(Monitor);
