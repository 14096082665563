import {
    ADD_USER_LOADING,
    ADD_USER_SUCCESS,
    ADD_USER_ERROR
} from './contants'

export function addUser(name, last_name, mobile, user_id, access_key){
    return {
        type: ADD_USER_LOADING,
        name: name,
        last_name: last_name,
        mobile: mobile,
        user_id: user_id,
        access_key: access_key
    }
}

export function addUserSuccess(message){
    return {
        type: ADD_USER_SUCCESS,
        message: message
    }
}

export function setError(error){
    return {
        type: ADD_USER_ERROR,
        error: error
    }
}