import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import React from 'react';

class MapView extends React.Component {

    constructor(props){
        super(props)
    }

    render(){
        return (
            <Map 
                containerStyle={{
                    top: 0,
                    left: 0,
                    borderRadius: 10,
                    WebkitBorderRadius: 24
                }}
                initialCenter={{
                  lat: 13.1334,
                  lng: 77.5674
                }}
		center={{
                   lat: this.props.latitude || 13.1334,
                   lng: this.props.longitude ||77.5674
		}}
                style={{
                    width: '100%',
                    height: '400px'
                }}
                google={this.props.google}>
                    <Marker position={{
                        lat: this.props.latitude,
                        lng: this.props.longitude
                    }} ></Marker>
            </Map>
        )
    }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyCYMlX6xQ26Hf-fF690RtcTQc5WSZ4EXjI"
})(MapView)
