import { takeEvery, call, take, fork, put, all } from "redux-saga/effects"
import { apiUrl, fetchJSON } from "../../helpers/api"
import {
    DASHBOARD_DATA_LOADING,
    DASHBOARD_DATA_SUCCESS,
    DASHBOARD_DATA_ERROR
} from './contants'
import {
    setSummary,
    loadError
} from './actions'
import { getLoggedInUser } from "../../helpers/authUtils"

function* fetchSummary(){
    try {
        let summary = yield call(fetchJSON, `${apiUrl}/admin/summary/`, {
        // let summary = yield call(fetchJSON, 'https://outriderapi.9930i.com/admin/summary/', {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${getLoggedInUser()}`,
                "Content-Type": "application/json"
            }
        })
        yield put(setSummary(summary))
    } catch(error){
        yield put(loadError(error))
    }
}


export function* watchLoadSummary(){
    yield takeEvery(DASHBOARD_DATA_LOADING, fetchSummary)
}

export default function* usersFlow() {
    yield all([
        fork(watchLoadSummary)
    ])
}