/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const fetchJSON = (url, options = {}) => {
  return fetch(url, options)
    .then((response) => {
      console.log("url >>>>>", url);
      // console.log("options>>>>>", options);
      if (!response.status === 200) {
        throw response.json();
      }
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      throw error;
    });
};
let apiUrl;
if (
  process.env.REACT_APP_NODE_ENV === "beta" ||
  process.env.NODE_ENV === "development"
) {
  apiUrl = process.env.REACT_APP_BASE_URL_BETA;
} else {
  apiUrl = process.env.REACT_APP_BASE_URL_PROD;
}
export { fetchJSON, apiUrl };
