import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import * as FeatherIcon from 'react-feather';

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';
import Monitor from '../pages/monitor';
import Users from '../pages/users/Users';
import AllDevices from '../pages/devices/AllDevices';
import UserDevices from '../pages/devices/UserDevices';
import AddDevice from '../pages/devices/AddDevice';
import DeviceStatus from '../pages/devices/DeviceStatus';
import AddUser from '../pages/users/AddUser';

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
// dashboard
const Dashboard = React.lazy(() => import('../pages/dashboard'));
// apps
const CalendarApp = React.lazy(() => import('../pages/apps/Calendar'));
const EmailInbox = React.lazy(() => import('../pages/apps/Email/Inbox'));
const EmailDetail = React.lazy(() => import('../pages/apps/Email/Detail'));
const EmailCompose = React.lazy(() => import('../pages/apps/Email/Compose'));
const ProjectList = React.lazy(() => import('../pages/apps/Project/List'));
const ProjectDetail = React.lazy(() => import('../pages/apps/Project/Detail/'));
const TaskList = React.lazy(() => import('../pages/apps/Tasks/List'));
const TaskBoard = React.lazy(() => import('../pages/apps/Tasks/Board'));

// pages
const Starter = React.lazy(() => import('../pages/other/Starter'));
const Profile = React.lazy(() => import('../pages/other/Profile/'));
const Activity = React.lazy(() => import('../pages/other/Activity'));
const Invoice = React.lazy(() => import('../pages/other/Invoice'));
const Pricing = React.lazy(() => import('../pages/other/Pricing'));
const Error404 = React.lazy(() => import('../pages/other/Error404'));
const Error500 = React.lazy(() => import('../pages/other/Error500'));

// ui
const BSComponents = React.lazy(() => import('../pages/uikit/BSComponents/'));
const FeatherIcons = React.lazy(() => import('../pages/uikit/Icons/Feather'));
const UniconsIcons = React.lazy(() => import('../pages/uikit/Icons/Unicons'));
const Widgets = React.lazy(() => import('../pages/uikit/Widgets/'));

// charts
const Charts = React.lazy(() => import('../pages/charts/'));

// forms
const BasicForms = React.lazy(() => import('../pages/forms/Basic'));
const FormAdvanced = React.lazy(() => import('../pages/forms/Advanced'));
const FormValidation = React.lazy(() => import('../pages/forms/Validation'));
const FormWizard = React.lazy(() => import('../pages/forms/Wizard'));
const FileUpload = React.lazy(() => import('../pages/forms/FileUpload'));
const Editor = React.lazy(() => import('../pages/forms/Editor'));

// tables
const BasicTables = React.lazy(() => import('../pages/tables/Basic'));
const AdvancedTables = React.lazy(() => import('../pages/tables/Advanced'));


// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            }

            const loggedInUser = getLoggedInUser();
            // check if route is restricted by role
            if (!loggedInUser) {
                // role not authorised so redirect to home page
                return <Redirect to={{ pathname: '/' }} />;
            }

            // authorised so return component
            return <Component {...props} />;
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/analytics/home/" />,
    route: PrivateRoute,
};

// dashboards
const dashboardRoutes = {
    path: '/analytics/home/',
    name: 'Analytics',
    icon: FeatherIcon.TrendingUp,
    component: Dashboard,
    route: PrivateRoute
};

const userRoutes = {
    name: 'Users',
    icon: FeatherIcon.Users,
    path: '/analytics/users/',
    component: Users,
    route: PrivateRoute,
    
};
const userAddRoutes = {
    name: 'Users',
    icon: FeatherIcon.UserPlus,
    path: '/analytics/user/add',
    component: AddUser,
    route: PrivateRoute,
};

// pages
const deviceRoutes = {
    path: '/analytics/devices',
    name: 'Devices',
    icon: FeatherIcon.Cpu,
    children: [
        {   
            path: '/analytics/devices/all/',
            component: AllDevices,
            name: 'All Devices',
            route: PrivateRoute
        },
        {   
            path: '/analytics/devices/assigned/',
            component: UserDevices,
            name: 'User Devices',
            route: PrivateRoute
        },
        {   
            path: '/analytics/devices/add/',
            component: AddDevice,
            name: 'Add Device',
            route: PrivateRoute
        },
        {   
            path: '/analytics/devices/devicestatus/',
            component: DeviceStatus,
            name: 'Device Status',
            route: PrivateRoute
        }
    ]
};

const allMonitorRoutes = {
    path: '/monitor/index/',
    component: Charts,
    name: 'Monitor',
    
    icon: FeatherIcon.Monitor,
    route: PrivateRoute,
    
}

const singleMonitorRoutes = {
    path: '/monitor/:deviceID',
    component: Monitor,
    name: 'Monitor',
    
    icon: FeatherIcon.Monitor,
    route: PrivateRoute
    
}

const PaymentRoutes = {
    path: '/analytics/payments/',
    component: EmailCompose,
    name: 'Payments',
    
    icon: FeatherIcon.DollarSign,
    route: PrivateRoute,
}

const SettlementRoutes = {
    path: '/analytics/settlements/',
    component: BasicForms,
    name: 'Settlements',
    
    icon: FeatherIcon.Copy,
    route: PrivateRoute,
}

const FeedbackRoutes = {

}

const ReportRoutes = {

}


// components
const componentsRoutes = {
    path: '/ui',
    name: 'UI Elements',
    header: 'Components',
    icon: FeatherIcon.Package,
    children: [
        {
            path: '/ui/bscomponents',
            name: 'Bootstrap UI',
            component: BSComponents,
            route: PrivateRoute,
            
        },
        {
            path: '/ui/icons',
            name: 'Icons',
            children: [
                {
                    path: '/ui/icons/feather',
                    name: 'Feather Icons',
                    component: FeatherIcons,
                    route: PrivateRoute,
                    
                },
                {
                    path: '/ui/icons/unicons',
                    name: 'Unicons Icons',
                    component: UniconsIcons,
                    route: PrivateRoute,
                    
                },
            ]
        },
        {
            path: '/ui/widgets',
            name: 'Widgets',
            component: Widgets,
            route: PrivateRoute,
            
        },

    ]
};

// charts
const chartRoutes = {
    path: '/charts',
    name: 'Charts',
    component: Charts,
    icon: FeatherIcon.PieChart,
    
    route: PrivateRoute
}


// forms
const formsRoutes = {
    path: '/forms',
    name: 'Forms',
    icon: FeatherIcon.FileText,
    children: [
        {
            path: '/forms/basic',
            name: 'Basic Elements',
            component: BasicForms,
            route: PrivateRoute,
        },
        {
            path: '/forms/advanced',
            name: 'Advanced',
            component: FormAdvanced,
            route: PrivateRoute,
        },
        {
            path: '/forms/validation',
            name: 'Validation',
            component: FormValidation,
            route: PrivateRoute,
        },
        {
            path: '/forms/wizard',
            name: 'Wizard',
            component: FormWizard,
            route: PrivateRoute,
        },
        {
            path: '/forms/editor',
            name: 'Editor',
            component: Editor,
            route: PrivateRoute,
        },
        {
            path: '/forms/upload',
            name: 'File Upload',
            component: FileUpload,
            route: PrivateRoute,
        }
    ]
};


const tableRoutes = {
    path: '/tables',
    name: 'Tables',
    icon: FeatherIcon.Grid,
    children: [
        {
            path: '/tables/basic',
            name: 'Basic',
            component: BasicTables,
            route: PrivateRoute,
        },
        {
            path: '/tables/advanced',
            name: 'Advanced',
            component: AdvancedTables,
            route: PrivateRoute,
        }]
};


// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/register',
            name: 'Register',
            component: Register,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
    ],
};

// flatten the list of all nested routes
const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [
    rootRoute,
    allMonitorRoutes,
    dashboardRoutes,
    deviceRoutes,
    singleMonitorRoutes,
    userRoutes,
    userAddRoutes,
    authRoutes
];

const authProtectedRoutes = [dashboardRoutes, userRoutes, deviceRoutes ];
const allFlattenRoutes = flattenRoutes(allRoutes);
export { PaymentRoutes, SettlementRoutes, allRoutes, authProtectedRoutes, allFlattenRoutes };
