import {
    DEVICESTATUS_LOADING,
    DEVICESTATUS_SUCCESS,
    DEVICESTATUS_ERROR
} from './contants'

const INITIAL_STATE = {
    devices: [],
    is_loading: true,
    message: ""
}

const DevicesStatusReducer = (state=INITIAL_STATE, action) => {
    switch(action.type){
        case DEVICESTATUS_LOADING:
            return state
        case DEVICESTATUS_SUCCESS:
            // console.log(action.devices)
            const devicesId = [];
            if(action.devices && Array.isArray(action.devices) && action.devices.length>0){
                action.devices.forEach((device)=>{
                    device.id = device._id;
                    devicesId.push(device)
                })
            }
            // console.log(devicesId)
            return {
                ...state,
                is_loading: false,
                devices: devicesId
            }
        case DEVICESTATUS_ERROR:
            return {
                ...state,
                devices: [],
                is_loading: false,
                message: action.error
            }
        default:
            return state
    }
}

export default DevicesStatusReducer