import {
    DASHBOARD_DATA_LOADING,
    DASHBOARD_DATA_SUCCESS,
    DASHBOARD_DATA_ERROR
} from './contants'

const INITIAL_STATE = {
    summary: {
        "devices": 0,
        "users": 0,
        "user_devices": 0
    },
    is_loading: true,
    message: ""
}

const SummaryReducer = (state=INITIAL_STATE, action) => {
    switch(action.type){
        case DASHBOARD_DATA_LOADING:
            return state
        case DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                is_loading: false,
                summary: {
                    devices: action.payload.devices,
                    users: action.payload.users,
                    user_devices: action.payload.user_devices
                }
            }
        case DASHBOARD_DATA_ERROR:
            return {
                ...state,
                users: [],
                is_loading: false,
                message: action.error
            }
        default:
            return state
    }
}

export default SummaryReducer